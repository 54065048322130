import React, { ChangeEvent, FC, FormEvent, useEffect, useRef, useState } from 'react'
import PatientDataCard from '../common/PatientDataCard';
import CustomContainer from '../common/CustomContainer';
import { Chat2Icon, ChatFileIcon, ChatIcon, ChatSendIcon, EndCallIcon, FullScreenIcon, InFoIcon, Mic2Icon, MicIcon, ScreenshareIcon, SwitchCameraIcon, Video2Icon, VideoIcon } from '../../utils/functions/Icons';
import { Images } from '../../utils/functions/Images';
import { FormGroup, InputAdornment, TextField } from '@mui/material';
import OT from "@opentok/client";
import { AppointmentDetaisApi, AppointmentDetaisByPatientIdApi, callEndByPatientApi, disconnectReasonApi, getGenerateCallIdByPatientApi, getTalkToDoctorApi, updateCallRecordCallEndApi, updateStatusByCallIdApi, uploadFileApi } from '../../utils/api/services';
import { toast } from 'react-toastify';
import ConfirmModal from '../common/ConfirmModal';
import { useNavigate } from 'react-router-dom';
import CallDisconnectMadal from '../common/CallDisconnectModal';
import VideoCallSidebar from './VideoCallSidebar';
import { useDispatch, useSelector } from 'react-redux';
import { AppDispatch, RootState } from '../../utils/store';
import { handleToggle } from '../../utils/store/common/CommonSlice';
import styles from "../../assets/styles/videocall.module.css"
import useSocket from '../../utils/functions/useSocket';
import { getFileExtension } from '../../utils/functions/common';

interface Props {
  apiKey: string;
  sessionId: string;
  token: string;
  TalkToDoctorData: WaitingListItem | null;
  callid: number | null
}
interface CameraDevice {
  deviceId: string;
}
const VideoCall: FC<Props> = ({ apiKey, sessionId, token, TalkToDoctorData, callid }) => {
  const [showChat, setShowChat] = useState(false);
  const [isMuted, setIsMuted] = useState<boolean>(false);
  const [isVideoOff, setIsVideoOff] = useState<boolean>(false);
  const [isSwitchCamera, setIsSwitchCamera] = useState<boolean>(false);
  const [connected, setConnected] = useState(false)
  const [appointmentDetails, setAppointmentDetails] = useState<AppointmentDetailsResponse | null>(null);
  const [loading, setLoading] = useState(false);
  const [isSharingScreen, setIsSharingScreen] = useState<boolean>(false);
  const [openConfirmModal, setOpenConfirmModal] = useState(false)
  const [chatMessages, setChatMessages] = useState<{ text: string; className: string, isPtient: boolean }[]>([]);
  const [messages, setMessages] = useState<ChatHistoryItem[] | []>([]);
  const [newMessage, setNewMessage] = useState<string>('');
  // switch camera
  const [cameras, setCameras] = useState<CameraDevice[]>([]);
  const [currentCameraIndex, setCurrentCameraIndex] = useState<number>(0);
  // switch camera
  const { videoCallActiveTab } = useSelector((state: RootState) => state.common)
  const { connect, disconnect, sendMessage, listenForEvent } = useSocket();
  const { LoginData } = useSelector((state: RootState) => state.auth)


  const sessionRef = useRef<OT.Session | null>(null);
  const publisherRef = useRef<OT.Publisher | null>(null);
  const screenPublisherRef = useRef<OT.Publisher | null>(null);
  const navigate = useNavigate();
  const dispatch = useDispatch<AppDispatch>()
  // useEffect(() => {
  //   const handleBeforeUnload = (event: BeforeUnloadEvent) => {
  //     event.preventDefault();
  //     event.returnValue = 'Are you sure you want to end your call?';
  //   };

  //   window.addEventListener('beforeunload', handleBeforeUnload);

  //   return () => {
  //     window.removeEventListener('beforeunload', handleBeforeUnload);
  //   };
  // }, []);

  const handleError = (error: any) => {
    if (error) {
      alert(error.message);
    }
  };

  const initializeSession = () => {
    if (sessionRef.current) {
      return;
    }

    const session = OT.initSession(apiKey, sessionId);
    sessionRef.current = session;

    const onStreamCreated = (event: any) => {
      session.subscribe(
        event.stream,
        "subscriber",
        {
          insertMode: "append",
          width: "100%",
          height: "100%",
        },
        handleError
      );
    };
    session.on("streamCreated", onStreamCreated);


    if (!publisherRef.current) {
      const publisher = OT.initPublisher(
        "publisher",
        {
          insertMode: 'append',
          width: '100%',
          height: '100%',
        },
        handleError
      );
      publisherRef.current = publisher;
    }

    session.connect(token, (error) => {
      if (error) {
        handleError(error);
      } else {
        session.publish(publisherRef.current!, handleError);
        setConnected(true);
      }
    });

    return () => {
      if (sessionRef.current) {
        sessionRef.current.off("streamCreated", onStreamCreated);
        sessionRef.current = null;
      }
    };
  };

  useEffect(() => {
    const cleanup = initializeSession();
    return cleanup;
  }, [apiKey, sessionId, token]);

  const toggleMute = () => {
    const publisher = publisherRef.current;
    if (publisher) {
      const shouldMute = !isMuted;
      publisher.publishAudio(!shouldMute);
      setIsMuted(shouldMute);
    }
  };

  const handleShowChat = () => {
    setShowChat(!showChat);
  };

  const toggleVideo = () => {
    const publisher = publisherRef.current;
    if (publisher) {
      const shouldTurnVideoOff = !isVideoOff;
      publisher.publishVideo(!shouldTurnVideoOff);
      setIsVideoOff(shouldTurnVideoOff);
    }
  };

  const fetchUpdateStatusByCallId = async (data: any) => {
    try {

      const result = await updateStatusByCallIdApi(data);
      if (result?.success) {
        setOpenConfirmModal(false);
        dispatch(handleToggle())
        // navigate(-1)
      }
    } catch (error: any) {
      toast.error(error?.data?.status_message || error?.message);
    }
  }
  const pendingCall = async () => {
    try {
      if (sessionRef.current) {
        sessionRef.current.disconnect();
        publisherRef.current = null;
        const sendData = {
          "call_id": callid,
          "status": "PENDING",
          "callEnd": 1,
          "reason": ""
        }
        fetchUpdateStatusByCallId(sendData)
        try {
          await updateCallRecordCallEndApi({ patientId: TalkToDoctorData?.patient_id, status: 'COMPLETED' })
        } catch (error) {

        }
        await sendMessage('declinefromDoctor', { doctorId: LoginData?.id, patientId: TalkToDoctorData?.patient_id, connectDocId: LoginData?.connectDocId })
        sessionStorage.removeItem('waitingList');
        window.close();
      }
    } catch (error) {
      console.log(error)
    }
  }

  const endCall = async () => {
    try {
      if (sessionRef.current) {
        sessionRef.current.disconnect();
        publisherRef.current = null;
        const sendData = {
          "call_id": callid,
          "status": "COMPLETED",
          "callEnd": 1,
          "reason": ""
        }
        fetchUpdateStatusByCallId(sendData)
        try {
          await updateCallRecordCallEndApi({ patientId: TalkToDoctorData?.patient_id, status: 'COMPLETED' })
        } catch (error) {

        }
        await sendMessage('declinefromDoctor', { doctorId: LoginData?.id, patientId: TalkToDoctorData?.patient_id, connectDocId: LoginData?.connectDocId })
        sessionStorage.removeItem('waitingList');
        window.close();
      }
    } catch (error) {

    }
  };

  const CloseConfirmModal = () => {
    setOpenConfirmModal(false);
  };

  const handleEndCall = () => {
    setOpenConfirmModal(true);
  };

  const toggleScreenShare = () => {
    const session = sessionRef.current;
    if (session) {
      if (isSharingScreen) {
        if (screenPublisherRef.current) {
          session.unpublish(screenPublisherRef.current);
          screenPublisherRef.current.destroy();
          screenPublisherRef.current = null;
        }
      } else {
        const screenPublisher = OT.initPublisher(
          "publisher",
          {
            videoSource: "screen",
            insertMode: "append",
            width: "100%",
            height: "100%",
          },
          handleError
        );
        screenPublisherRef.current = screenPublisher;
        session.publish(screenPublisher, handleError);
      }
      setIsSharingScreen(!isSharingScreen);
    }
  };

  const toggleFullScreen = () => {
    const elem = document.documentElement;
    if (!document.fullscreenElement) {
      elem.requestFullscreen().catch((err) => {
        alert(`Error attempting to enable full-screen mode: ${err.message} (${err.name})`);
      });
    } else {
      document.exitFullscreen();
    }
  };

  // Switch camera functionality
  const listCameras = () => {
    OT.getDevices((error, devices) => {
      if (error) {
        console.error('Error listing media devices:', error);
        return;
      }
      const videoDevices: CameraDevice[] = devices
        ?.filter(device => device?.kind === "videoInput")
        .map(device => ({ deviceId: device?.deviceId })) || [];
      setCameras(videoDevices);
    });
  };

  useEffect(() => {
    if (connected) {
      listCameras();
    }
  }, [connected]);

  const switchCamera = async (deviceId: string) => {
    if (!sessionRef?.current || !publisherRef?.current) return;

    sessionRef?.current?.unpublish(publisherRef.current);
    publisherRef?.current?.destroy();
    const newPublisher = OT.initPublisher(
      'publisher',
      {
        videoSource: deviceId,
        insertMode: 'append',
        width: '100%',
        height: '100%',
      },
      handleError
    );

    publisherRef.current = newPublisher;
    sessionRef.current?.publish(newPublisher, handleError);
    setIsSwitchCamera(!isSwitchCamera);
  };

  const cycleCamera = () => {
    const nextIndex = (currentCameraIndex + 1) % cameras.length;
    setCurrentCameraIndex(nextIndex);
    switchCamera(cameras[nextIndex].deviceId);
  };
  // switch camera end

  // chat start

  useEffect(() => {
    connect();
    return () => {
      disconnect();
    };
  }, [connect, disconnect]);

  const IntiatChat = async () => {
    try {
      if (LoginData?.id) {
        await sendMessage('listen', { userId: LoginData?.id, userType: 2 });
      }
    } catch (error) {

    }
  }

  useEffect(() => {
    listenForEvent(`chatHistory`, (data: ChatHistorySocket) => {
      if (data?.senderId === LoginData?.id && data?.senderType === 2) {
        setMessages(data?.senderChatHistory);
      }
      if (data?.receiverId === LoginData?.id && data?.receiverType === 2) {
        setMessages(data?.receiverChatHistory);
      }
    });
    IntiatChat();
  }, [listenForEvent])

  useEffect(() => {
    let callEndedToastShown = false;
    listenForEvent(`declinefromPatientFront`, (data: any) => {
      if (data?.patientId && !callEndedToastShown) {
        callEndedToastShown = true;
        toast.info('The patient has ended the call.');
      }
    });
  }, [])

  const fetchApiUrl = async (url: string) => {
    try {
      const result = await uploadFileApi({ file: url, type: 'chat' })
      if (result?.success) {
        return result?.fileUrl
      }
    } catch (error: any) {
      toast.error(error?.data?.errors || error?.message);
    }
  }

  const handleChangeMsg = (e: ChangeEvent<HTMLInputElement>) => {
    setNewMessage(e.target.value)
  }
  const handleSendMessage = async (e: FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    if (newMessage.trim()) {
      const sendData = {
        senderId: LoginData?.id,
        senderType: 2,
        receiverId: TalkToDoctorData?.patient_id,
        receiverType: 1,
        message: newMessage,
        messageType: newMessage !== '' ? 1 : 2,
      }
      try {
        await sendMessage('sendMessage', sendData);
      } catch (error: any) {
        console.error(error.message);
      }
      setNewMessage('');
    }
  };

  const handleFileChange = async (e: ChangeEvent<HTMLInputElement>) => {
    e.preventDefault();
    const file = e.target.files?.[0] ?? null;
    if (file) {
      const reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = async () => {
        const base64Data = reader.result as string;
        if (base64Data) {
          const fileUrl = await fetchApiUrl(base64Data)
          const sendData = {
            senderId: LoginData?.id,
            senderType: 2,
            receiverId: TalkToDoctorData?.patient_id,
            receiverType: 1,
            message: fileUrl?.fileUrl,
            messageType: 2,
          }
          try {
            await sendMessage('sendMessage', sendData);
          } catch (error: any) {
            console.error(error.message);
          }
          setNewMessage('');
        }
      };
      reader.onerror = (error) => console.error("Error reading file:", error);
    }
  }
  const fetchAppointmentDetails = async (apId: number | null, ptId: number) => {
    try {
      setLoading(true);
      const result = (apId && ptId) ? await AppointmentDetaisApi(apId, ptId) : await AppointmentDetaisByPatientIdApi(ptId);
      if (result?.success) {
        setLoading(false);
        setAppointmentDetails(result?.response);
      } else {
        setLoading(false);
      }
    } catch (error: any) {
      setLoading(false);
      toast.error(error?.data?.errors || error?.message);;
    }
  };

  useEffect(() => {
    const storedWaitingList = sessionStorage.getItem('waitingList');
    const waitingList = storedWaitingList && JSON.parse(storedWaitingList)
    if (waitingList?.appointmentId && waitingList?.patient_id) {
      fetchAppointmentDetails(waitingList?.appointmentId, waitingList?.patient_id);
    }
    else {
      fetchAppointmentDetails(null, waitingList?.patient_id);
    }
  }, []);

  // handling navigation
  useEffect(() => {
    window.history.pushState(null, '', window.location.href);
    const handlePopState = () => {
      endCall();
    };
    window.addEventListener('popstate', handlePopState);
    return () => {
      window.removeEventListener('popstate', handlePopState);
    };
  }, [navigate]);

  useEffect(() => {
    const handleBeforeUnload = (event: BeforeUnloadEvent) => {
      event.preventDefault();
      event.returnValue = 'Are you sure you want to end your call?';
    };

    window.addEventListener('beforeunload', handleBeforeUnload);

    return () => {
      window.removeEventListener('beforeunload', handleBeforeUnload);
    };
  }, []);
  // handling navigation

  return (
    <CustomContainer>
      <div className={'AllPageMain'}>
        <div className='headerMain'>
          {
            appointmentDetails?.patientDetails &&
            <PatientDataCard
              profilePic={appointmentDetails?.patientDetails?.profilePic || ''}
              name={appointmentDetails?.patientDetails?.name}
              patientId={appointmentDetails?.patientDetails?.patientId}
              age={appointmentDetails?.patientDetails?.age}
              dateofbirth={appointmentDetails?.patientDetails?.dateofbirth}
              gender={appointmentDetails?.patientDetails?.gender}
              location={appointmentDetails?.patientDetails?.location}
              phone={appointmentDetails?.patientDetails?.phone}
            />
          }
        </div>
        <div className={styles.videoCallAndSidebarMain}>
          <div className={`VideoCallMain ${styles.videoCallMain} ${videoCallActiveTab !== "" ? styles.ActiveTab : ''}`}>
            <div className={`grid ${showChat ? 'grid-cols-7' : 'grid-cols-1'} gap-4`}>
              <section className={`${showChat ? 'col-span-5' : ''} videoSection`}>
                <div className='VideoSectionMain'>
                  <div className='fullScreenModeIcon' onClick={toggleFullScreen}>
                    <FullScreenIcon />
                  </div>
                  <div className='videoScreens'>
                    <div id="subscriber" className={`SubscriberConnected`}></div>
                    <div id="publisher" className='PublisherConnected'></div>
                  </div>
                </div>
                <div className={`controlesBtns`}>
                  <div className={`roundedBtns ${isMuted ? 'active' : ''}`} onClick={toggleMute}>
                    {
                      isMuted ? <Mic2Icon /> : <MicIcon />
                    }

                  </div>
                  <div className={`roundedBtns ${isVideoOff ? 'active' : ''}`} onClick={toggleVideo}>
                    {
                      isVideoOff ? <Video2Icon /> : <VideoIcon />
                    }
                  </div>
                  <div title='Switch Camera' className={`roundedBtns ${isSwitchCamera ? 'active' : ''}`} onClick={cycleCamera}>
                    <SwitchCameraIcon />
                  </div>
                  <div className={`roundedBtns ${showChat ? 'active' : ''}`} onClick={handleShowChat}>
                    {
                      showChat ? <Chat2Icon /> : <ChatIcon />
                    }
                  </div>
                  <div className={`roundedBtns ${isSharingScreen ? 'active' : ''}`} onClick={toggleScreenShare}>
                    <ScreenshareIcon />
                  </div>
                  <div className='callEndBtn' onClick={handleEndCall}>
                    <EndCallIcon />  End Call
                  </div>
                </div>
              </section>
              {showChat && (
                <section className={`chatSection ${showChat ? 'col-span-2' : ''}`}>
                  <div className='chatMainDiv'>
                    <div className='chatHeader'>
                      <div className='chatDocNameImg'>
                        <img src={appointmentDetails?.patientDetails?.profilePic ? appointmentDetails?.patientDetails?.profilePic : Images.avtarIcon} alt="doctor image" />
                        <div className='namePro'>
                          <h3>{appointmentDetails?.patientDetails?.name}</h3>
                          <p>Patient ID: {appointmentDetails?.patientDetails?.patientId}</p>
                        </div>
                      </div>
                      {/* <div className='chatInfoIcons'>
                        <InFoIcon />
                      </div> */}
                    </div>
                    <div className='chatBody'>
                      {
                        messages?.map((item, index) => {
                          return (
                            <React.Fragment key={index}>
                              <div className={styles.chatDateMain}>
                                <div className={styles.chatDate}>
                                  <p>{item?.date}</p>
                                </div>
                              </div>
                              {item?.messages.map((msg, id) => {
                                const fileExtension = msg.messageType === 2 && getFileExtension(msg?.message);
                                const isImage = msg.messageType === 2 && fileExtension && ['jpg', 'jpeg', 'png', 'gif', 'bmp'].includes(fileExtension); // Check if it's an image
                                return (
                                  <React.Fragment key={id}>
                                    {
                                      msg?.type === "received" ?
                                        <div className='doctorChat'>
                                          <div className='patientImg'>
                                            <img src={msg?.profilePic ? msg?.profilePic : Images.avtarIcon} alt="Patient Image" />
                                          </div>
                                          <div className={`DoctorMsg ${msg?.messageType === 2 ? 'noBgForThis' : ''}`}>
                                            {
                                              msg?.messageType === 1 ?
                                                <p>
                                                  {msg?.message}
                                                </p>
                                                :
                                                <>
                                                  {
                                                    isImage ?
                                                      <img src={msg?.message} alt={`image${id}`} />
                                                      :
                                                      <img onClick={() => window.open(msg?.message)} className={styles.fileIcon} src={Images.filesIcon} alt={`file${id}`} />
                                                  }
                                                </>
                                            }
                                            <span>{msg?.time}</span>
                                          </div>
                                        </div>
                                        :
                                        <div className='patientChat'>
                                          <div className={`patientMsg ${msg?.messageType === 2 ? 'noBgForThis' : ''}`}>
                                            {
                                              msg?.messageType === 1 ?
                                                <p>
                                                  {msg?.message}
                                                </p>
                                                : <>
                                                  {
                                                    isImage ?
                                                      <img src={msg?.message} alt={`image${id}`} />
                                                      :
                                                      <img onClick={() => window.open(msg?.message)} className={styles.fileIcon} src={Images.filesIcon} alt={`file${id}`} />
                                                  }
                                                </>
                                            }
                                            <span>{msg?.time}</span>
                                          </div>
                                          <div className='patientImg'>
                                            <img src={msg?.profilePic ? msg?.profilePic : Images.avtarIcon} alt="Doctor Image" />
                                          </div>
                                        </div>
                                    }
                                  </React.Fragment>
                                )
                              })}
                            </React.Fragment>
                          )
                        })
                      }
                    </div>
                    <div className='chatFooter'>
                      <form onSubmit={handleSendMessage}>
                        <FormGroup className="customFormGroup">
                          <TextField
                            className={`custumTextField custumTextFieldChat`}
                            id="newMessage"
                            name="newMessage"
                            placeholder='Message here'
                            onChange={handleChangeMsg}
                            value={newMessage}
                            InputProps={{
                              endAdornment: (
                                <InputAdornment position="end">
                                  <div className='fileIcon'>
                                    <input
                                      type="file"
                                      name={'file_upload'}
                                      id={'file_upload'}
                                      accept={'.jpeg,.jpg,.png,.txt,.xlsx,.xls,.csv,.pdf,.doc,.docx'}
                                      multiple={false}
                                      className="customFileInput"
                                      onChange={handleFileChange}
                                      style={{ display: 'none' }}
                                    />
                                    <label className={`cursor-pointer`} htmlFor={'file_upload'}>
                                      <ChatFileIcon />
                                    </label>
                                  </div>
                                  <button type='submit' className='sendMessageBtn ml-4'>
                                    <ChatSendIcon />
                                  </button>
                                </InputAdornment>
                              ),
                            }}
                          />
                        </FormGroup>
                      </form>
                    </div>
                  </div>
                </section>
              )}
            </div>
          </div>
          <div className={`${styles.sidebarSecMain} ${videoCallActiveTab !== "" ? styles.ActiveTab : ''}`}>
            <VideoCallSidebar TalkToDoctorData={TalkToDoctorData} appointmentDetails={appointmentDetails} />
          </div>
        </div>
      </div>
      <CallDisconnectMadal
        title="Call Status?"
        openConfirmModal={openConfirmModal}
        confirmSubmit={endCall}
        pendingCall={pendingCall}
        CloseConfirmModal={CloseConfirmModal}
      />
    </CustomContainer>
  )
}

export default VideoCall;
